import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import type { CptCode, Criteria, Procedure } from '../types';
import { LocalStorageService } from '../services/LocalStorageService';
import { logInfo, logError } from '../utils/logging';
import { useAuth } from './AuthContext';
import { withRetry } from '../utils/retry';

interface DataContextType {
  cptCodes: CptCode[];
  criteria: Criteria[];
  procedures: Procedure[];
  loading: { [key: string]: boolean };
  error: string | null;
  loadData: (collectionName: string) => Promise<void>;
  updateData: (collectionName: string, data: any[]) => Promise<void>;
  refreshData: () => Promise<void>;
}

const DataContext = createContext<DataContextType>({
  cptCodes: [],
  criteria: [],
  procedures: [],
  loading: {},
  error: null,
  loadData: async () => {},
  updateData: async () => {},
  refreshData: async () => {}
});

export const useData = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useData must be used within a DataProvider');
  }
  return context;
};

export const DataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  const [state, setState] = useState<{
    cptCodes: CptCode[];
    criteria: Criteria[];
    procedures: Procedure[];
    loading: { [key: string]: boolean };
    error: string | null;
  }>({
    cptCodes: [],
    criteria: [],
    procedures: [],
    loading: {},
    error: null
  });

  const loadData = useCallback(async (collectionName: string) => {
    setState(prev => ({
      ...prev,
      loading: { ...prev.loading, [collectionName]: true },
      error: null
    }));

    try {
      const data = await withRetry(
        () => LocalStorageService.getData(collectionName),
        {
          maxRetries: 3,
          delayMs: 1000,
          onRetry: (attempt) => {
            logInfo(`Retrying data load for ${collectionName} (attempt ${attempt})`);
          }
        }
      );

      setState(prev => ({
        ...prev,
        [collectionName]: data,
        loading: { ...prev.loading, [collectionName]: false }
      }));

      logInfo(`Successfully loaded ${collectionName} data`);
    } catch (error) {
      logError(`Failed to load ${collectionName}:`, error);
      setState(prev => ({
        ...prev,
        error: `Failed to load ${collectionName}`,
        loading: { ...prev.loading, [collectionName]: false }
      }));
    }
  }, []);

  const updateData = useCallback(async (collectionName: string, data: any[]) => {
    setState(prev => ({
      ...prev,
      loading: { ...prev.loading, [collectionName]: true },
      error: null
    }));

    try {
      await LocalStorageService.setData(collectionName, data);
      
      setState(prev => ({
        ...prev,
        [collectionName]: data,
        loading: { ...prev.loading, [collectionName]: false }
      }));

      logInfo(`Successfully updated ${collectionName} data`);
    } catch (error) {
      logError(`Failed to update ${collectionName}:`, error);
      setState(prev => ({
        ...prev,
        error: `Failed to update ${collectionName}`,
        loading: { ...prev.loading, [collectionName]: false }
      }));
    }
  }, []);

  const refreshData = useCallback(async () => {
    logInfo('Refreshing all data...');
    setState(prev => ({
      ...prev,
      loading: {
        cptCodes: true,
        criteria: true,
        procedures: true
      },
      error: null
    }));

    try {
      const [cptCodes, criteria, procedures] = await Promise.all([
        LocalStorageService.getData('cptCodes'),
        LocalStorageService.getData('criteria'), 
        LocalStorageService.getData('procedures')
      ]);

      setState(prev => ({
        ...prev,
        cptCodes,
        criteria,
        procedures,
        loading: {
          cptCodes: false,
          criteria: false,
          procedures: false
        }
      }));

      logInfo('Successfully refreshed all data');
    } catch (error) {
      logError('Failed to refresh data:', error);
      setState(prev => ({
        ...prev,
        error: 'Failed to refresh data',
        loading: {
          cptCodes: false,
          criteria: false,
          procedures: false
        }
      }));
    }
  }, []);

  // Load data when user logs in
  useEffect(() => {
    if (user) {
      refreshData();
    }
  }, [user, refreshData]);

  return (
    <DataContext.Provider value={{
      ...state,
      loadData,
      updateData,
      refreshData
    }}>
      {children}
    </DataContext.Provider>
  );
};