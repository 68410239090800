import React from 'react';
import OCRTester from '../components/ocr/OCRTester';

const OCRTest = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-900 via-blue-900 to-blue-800 py-8">
      <div className="max-w-4xl mx-auto px-4">
        <div className="relative group mb-8">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500/30 to-indigo-500/30 rounded-xl blur-xl"></div>
          <div className="relative backdrop-blur-md bg-white/10 border border-white/20 p-6 rounded-xl">
            <h1 className="text-2xl font-bold text-white mb-6">OCR API Tester</h1>
            <OCRTester />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OCRTest;