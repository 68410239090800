export class OpenAIError extends Error {
  constructor(
    message: string,
    public code: string = 'unknown_error',
    public status?: number,
    public originalError?: any
  ) {
    super(message);
    this.name = 'OpenAIError';
  }

  static fromError(error: any): OpenAIError {
    if (error instanceof OpenAIError) {
      return error;
    }

    const response = error?.response?.data;
    const status = error?.response?.status;

    if (status === 401) {
      return new OpenAIError(
        'Invalid API key configuration. Please check your settings.',
        'auth_error',
        status
      );
    }

    if (status === 402 || error.message?.includes('insufficient_quota')) {
      return new OpenAIError(
        'API quota exceeded. Please try again later.',
        'quota_exceeded',
        status
      );
    }

    if (status === 429) {
      return new OpenAIError(
        'Too many requests. Please try again later.',
        'rate_limit',
        status
      );
    }

    return new OpenAIError(
      response?.error?.message || error.message || 'An unknown error occurred',
      'api_error',
      status,
      error
    );
  }
}