import { encode } from 'gpt-tokenizer';
import { TOKEN_LIMITS } from '../config/constants';

export function truncateText(text: string): string {
  if (!text?.trim()) return '';

  const tokens = encode(text);
  const maxInputTokens = TOKEN_LIMITS.MAX_TOTAL_TOKENS - TOKEN_LIMITS.MAX_COMPLETION_TOKENS - TOKEN_LIMITS.SYSTEM_TOKENS;

  if (tokens.length <= maxInputTokens) {
    return text;
  }

  return text.slice(0, maxInputTokens * 4); // Approximate character count
}