export class RateLimiter {
  private lastRequestTime: number = 0;
  private readonly MIN_REQUEST_INTERVAL = 1000;

  async waitForAvailability(): Promise<void> {
    const now = Date.now();
    const timeSinceLastRequest = now - this.lastRequestTime;
    
    if (timeSinceLastRequest < this.MIN_REQUEST_INTERVAL) {
      await new Promise(resolve => 
        setTimeout(resolve, this.MIN_REQUEST_INTERVAL - timeSinceLastRequest)
      );
    }
    
    this.lastRequestTime = Date.now();
  }
}

export const rateLimiter = new RateLimiter();