import { openai } from './client';
import { openAISettings } from '../config/settings';
import { OpenAIError } from '../errors';
import { rateLimiter } from '../utils/rateLimiter';
import { truncateText } from '../utils/tokenizer';
import { handleOpenAIError } from '../utils/errorHandler';

export async function generateCompletion(
  text: string,
  systemPrompt: string,
  guidelines: string[] = []
): Promise<string> {
  if (!text?.trim()) {
    throw new OpenAIError('Text input is required', 'invalid_input');
  }

  let attempts = 0;
  const maxAttempts = openAISettings.retries;

  while (attempts < maxAttempts) {
    try {
      await rateLimiter.waitForAvailability();
      const truncatedText = truncateText(text);

      // Try with primary model first
      const model = attempts === 0 ? openAISettings.model : openAISettings.fallbackModel;

      const completion = await openai.chat.completions.create({
        model,
        messages: [
          {
            role: 'system',
            content: systemPrompt
          },
          {
            role: 'user',
            content: `${truncatedText}\n\n${guidelines.length > 0 ? `Guidelines to consider:\n${guidelines.join('\n')}\n\n` : ''}`
          }
        ],
        temperature: openAISettings.temperature,
        max_tokens: openAISettings.maxTokens
      });

      const content = completion.choices[0]?.message?.content;
      if (!content) {
        throw new OpenAIError('No response content from OpenAI', 'empty_response');
      }

      return content.trim();

    } catch (error: any) {
      attempts++;

      // Don't retry on certain errors
      if (error.status === 401 || error.message?.includes('invalid_api_key')) {
        throw new OpenAIError('Invalid API key configuration', 'auth_error', 401);
      }

      // Handle quota exceeded
      if (error.status === 402 || error.message?.includes('insufficient_quota')) {
        if (attempts < maxAttempts) {
          console.warn('Quota exceeded, trying fallback model...');
          continue;
        }
        throw new OpenAIError('API quota exceeded. Please try again later.', 'quota_exceeded', 402);
      }

      // Handle rate limits
      if (error.status === 429) {
        if (attempts < maxAttempts) {
          const delay = Math.pow(2, attempts) * 1000;
          await new Promise(resolve => setTimeout(resolve, delay));
          continue;
        }
        throw new OpenAIError('Rate limit exceeded. Please try again later.', 'rate_limit', 429);
      }

      if (attempts === maxAttempts) {
        return handleOpenAIError(error);
      }

      // Wait before retrying
      await new Promise(resolve => 
        setTimeout(resolve, openAISettings.timeout * attempts)
      );
    }
  }

  throw new OpenAIError('Failed to generate completion after multiple attempts', 'max_retries_exceeded');
}