import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { AISettings } from '../services/openai/types';

// Default AI settings with exact prompts
const DEFAULT_SETTINGS: AISettings = {
  medicalHistoryPrompt: `Briefly summarize the patient's condition. Write a briefparagraph explaining the patient's condition. Include diagnoses if mentioned but write everything in paragraph format. Avoid using numbers, bullet points, or breaking up sentences. Focus on the request and keep it concise. Remove references to the words "they" or "their." Do not use ICD-10 codes or list diagnoses by number. Do not list medications unless it is in relation to what is being requested.`,

  clinicalSummaryPrompt: `Briefly summarize the patient's condition. Briefly write 2 sentence explaining the patient's condition. Include diagnoses if mentioned but write everything in paragraph format. Avoid using numbers, bullet points, or breaking up sentences. Focus on the request and keep it concise. Remove references to the words "they" or "their." Do not use ICD-10 codes or list diagnoses by number. Do not list medications unless it is in relation to what is being requested.`,

  recommendationPrompt: `Look at the clinical notes and see if they qualify or meet the guidelines selected. Per each individual guideline, separately, write if the patient meets guidelines or does not meet guidelines. Write meet or does not meet in capital letters. Do not combine guidelines ever. Then write two sentences maximum per guideline explaining whether the patient meets the guideline and why or why not. Do not use phrasing like "Does the patient meet these guidelines?" Treat each one separately. Do not deviate when reviewing. Adhere strictly to the guidelines. There are no gray areas. Do not provide opinions. Provide no education in the review. Just state the facts. Don't just say findings align with the guidelines for medical necessity. Explain exactly why they align. Briefly explain the reasoning. Then, per guideline, Label "Guidelines States:" and write verbatim what the guideline specifies, exactly as provided. Review each guideline individually without skipping any. Do not alter, shorten, or deviate. If the guideline specifies denial, indicate it does not meet the criteria. Base decisions strictly on the guideline, not on opinions. Write in paragraph format. Make sure that the notes, Guideline and Guideline rationale all coincide together. Make sure it makes sense. If there are multiple guidelines I need to know individual if the guidelines met. Treat each one separately. Do not deviate when reviewing. Adhere strictly to the guidelines. There are no gray areas. Do not provide opinions. Provide no education in the review. Just state the facts. Don't just say findings align with the guidelines for medical necessity. Explain exactly why they align. No generic answers. For each Guideline keep them together. First explain why they meet or does not and then right after state what the guideline states as mentioned above. Just keep each guideline separately.

If the guideline title is "NCD - Durable Medical Equipment Reference List (280.1)," always deny the request. If reviewing Medicare A4670 and the patient does not have ESRD, write the following: "No evidence of being on Dialysis. Automatic blood pressure monitor/BP cuff is a non-covered item by Medicare except for patients on dialysis. Per Medicare Benefit Policy Manual Chapter 11, End Stage Renal Disease (ESRD), DME requested is covered for patients on home dialysis/peritoneal dialysis. A. Home Dialysis Equipment and Supplies. Instruments and non-medical supplies, such as scales, stopwatches, and blood pressure apparatus (this does not include automatic blood pressure monitoring devices such as those mentioned in Pub. 100-03, Medicare National Coverage Determinations Manual, Chapter 3), are included in the ESRD PPS, regardless of whether provided separately or as part of a start-up kit. Notes submitted do not document that the patient is on home dialysis." Do not number the guidelines when providing a decision. Capitalize Meet or Does Not Meet. When stating Guidelines States, it must be word for word, exactly what is written. Never make anything up. It must be verbatim.`,

  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString()
};

interface AISettingsState {
  settings: AISettings;
  initialized: boolean;
  updateSettings: (settings: AISettings) => void;
}

export const useAISettings = create<AISettingsState>()(
  persist(
    (set) => ({
      settings: DEFAULT_SETTINGS,
      initialized: false,
      updateSettings: (newSettings) => {
        if (!newSettings.medicalHistoryPrompt?.trim() ||
            !newSettings.recommendationPrompt?.trim() ||
            !newSettings.clinicalSummaryPrompt?.trim()) {
          throw new Error('All prompts are required');
        }
        
        set({
          settings: {
            ...newSettings,
            updatedAt: new Date().toISOString()
          },
          initialized: true
        });
      }
    }),
    {
      name: 'ai-settings',
      storage: createJSONStorage(() => localStorage),
      version: 1,
      partialize: (state) => ({
        settings: state.settings,
        initialized: state.initialized
      })
    }
  )
);