import React from 'react';
import { Loader2 } from 'lucide-react';

interface GuidelineInputProps {
  value: string;
  onChange: (value: string) => void;
  selectedLOB: string;
  onLOBChange: (value: string) => void;
  onProcess: () => void;
  disabled?: boolean;
}

export const GuidelineInput: React.FC<GuidelineInputProps> = ({
  value,
  onChange,
  selectedLOB,
  onLOBChange,
  onProcess,
  disabled
}) => {
  return (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-white mb-2">
          Line of Business (Optional)
        </label>
        <select
          value={selectedLOB}
          onChange={(e) => onLOBChange(e.target.value)}
          className="w-full px-3 py-2 bg-white/10 border border-white/20 rounded-lg text-white focus:ring-2 focus:ring-indigo-500 [&>*]:text-black"
          disabled={disabled}
        >
          <option value="">No LOB Selected</option>
          <optgroup label="Medicare Plans" className="text-black bg-white">
            <option value="Medicare">Medicare</option>
            <option value="Aetna Medicare">Aetna Medicare</option>
            <option value="BCBS Medicare">BCBS Medicare</option>
            <option value="Humana Medicare">Humana Medicare</option>
            <option value="UHC Medicare">UHC Medicare</option>
          </optgroup>

          <optgroup label="Medicaid Plans" className="text-black bg-white">
            <option value="Medicaid">Medicaid</option>
            <option value="Aetna Medicaid">Aetna Medicaid</option>
            <option value="BCBS Medicaid">BCBS Medicaid</option>
            <option value="Humana Medicaid">Humana Medicaid</option>
            <option value="Medi-Cal">Medi-Cal</option>
            <option value="UHC Medicaid">UHC Medicaid</option>
          </optgroup>

          <optgroup label="Commercial Plans" className="text-black bg-white">
            <option value="Commercial">Commercial</option>
            <option value="Aetna Commercial">Aetna Commercial</option>
            <option value="BCBS Commercial">BCBS Commercial</option>
            <option value="Humana Commercial">Humana Commercial</option>
            <option value="Medicare Commercial">Medicare Commercial</option>
            <option value="UHC Commercial">UHC Commercial</option>
          </optgroup>

          <optgroup label="Other Plans" className="text-black bg-white">
            <option value="Aetna">Aetna</option>
            <option value="Aetna Better Health">Aetna Better Health</option>
            <option value="Amerigroup">Amerigroup</option>
            <option value="Anthem">Anthem</option>
            <option value="BCBS">BCBS</option>
            <option value="Cigna">Cigna</option>
            <option value="Humana">Humana</option>
            <option value="Molina">Molina</option>
            <option value="UHC">UHC</option>
            <option value="United Healthcare">United Healthcare</option>
          </optgroup>
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-white mb-2">
          Guideline Text
        </label>
        <div className="relative">
          <textarea
            value={value}
            onChange={(e) => onChange(e.target.value)}
            rows={12}
            className="w-full px-3 py-2 bg-white/10 border border-white/20 rounded-lg text-white placeholder-white/50 focus:ring-2 focus:ring-indigo-500 scrollbar"
            placeholder="Paste guideline text here..."
            style={{ 
              height: '400px',
              resize: 'vertical',
              minHeight: '200px',
              maxHeight: '800px'
            }}
            disabled={disabled}
          />
        </div>
      </div>

      <div className="flex justify-end">
        <button
          onClick={onProcess}
          disabled={disabled || !value.trim()}
          className="px-6 py-3 bg-gradient-to-r from-blue-500 to-indigo-500 text-white rounded-xl hover:from-blue-600 hover:to-indigo-600 disabled:opacity-50 transition-all duration-300 shadow-lg hover:shadow-xl flex items-center"
        >
          {disabled ? (
            <>
              <Loader2 className="h-4 w-4 mr-2 animate-spin" />
              Processing...
            </>
          ) : (
            'Process Guideline'
          )}
        </button>
      </div>
    </div>
  );
};