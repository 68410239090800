import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import pako from "pako";

// Class name utilities
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// Compression utilities
export async function compress(str: string): Promise<string> {
  const uint8array = new TextEncoder().encode(str);
  const compressed = pako.deflate(uint8array);
  return btoa(String.fromCharCode(...compressed));
}

export async function decompress(compressedStr: string): Promise<string> {
  const binData = new Uint8Array(atob(compressedStr).split('').map(char => char.charCodeAt(0)));
  const decompressed = pako.inflate(binData);
  return new TextDecoder().decode(decompressed);
}

// Timing utilities
export function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number = 300
): (...args: Parameters<T>) => void {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;

  return function executedFunction(...args: Parameters<T>) {
    const later = () => {
      clearTimeout(timeoutId);
      func(...args);
    };

    clearTimeout(timeoutId);
    timeoutId = setTimeout(later, wait);
  };
}

export function throttle<T extends (...args: any[]) => any>(
  func: T,
  limit: number = 300
): (...args: Parameters<T>) => void {
  let inThrottle: boolean;
  let lastResult: ReturnType<T>;

  return function executedFunction(...args: Parameters<T>): ReturnType<T> {
    if (!inThrottle) {
      lastResult = func(...args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
    return lastResult;
  };
}