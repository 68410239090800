import React, { useState } from 'react';
import { Upload, Loader2, AlertCircle, FileText } from 'lucide-react';
import { OCRSpaceAPI } from '../../services/ocr/api/ocrSpaceApi';

const OCRTester = () => {
  const [file, setFile] = useState<File | null>(null);
  const [result, setResult] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
      setError(null);
      setResult('');
    }
  };

  const handleProcess = async () => {
    if (!file) return;

    setLoading(true);
    setError(null);
    setResult('');

    try {
      console.log('Starting OCR processing:', file.name);
      const text = await OCRSpaceAPI.processImage(file);
      setResult(text);
      console.log('OCR processing complete');
    } catch (err) {
      console.error('OCR error:', err);
      setError(err instanceof Error ? err.message : 'Failed to process file');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-center w-full">
        <label className="flex flex-col items-center justify-center w-full h-64 border-2 border-dashed rounded-lg cursor-pointer bg-white/5 border-white/20 hover:bg-white/10">
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            {file ? (
              <>
                <FileText className="w-8 h-8 mb-4 text-indigo-400" />
                <p className="text-sm text-white/80">
                  {file.name} ({(file.size / (1024 * 1024)).toFixed(2)} MB)
                </p>
              </>
            ) : (
              <>
                <Upload className="w-8 h-8 mb-4 text-white/80" />
                <p className="mb-2 text-sm text-white/80">
                  <span className="font-semibold">Click to upload</span> or drag and drop
                </p>
                <p className="text-xs text-white/60">
                  PDF, JPG, PNG (No size limit)
                </p>
              </>
            )}
          </div>
          <input
            type="file"
            className="hidden"
            onChange={handleFileChange}
            accept=".pdf,.jpg,.jpeg,.png"
          />
        </label>
      </div>

      <button
        onClick={handleProcess}
        disabled={!file || loading}
        className="w-full flex items-center justify-center px-4 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50 transition-colors"
      >
        {loading ? (
          <>
            <Loader2 className="w-5 h-5 mr-2 animate-spin" />
            Processing...
          </>
        ) : (
          'Process File'
        )}
      </button>

      {error && (
        <div className="p-4 bg-red-500/20 rounded-lg flex items-center">
          <AlertCircle className="h-5 w-5 text-red-400 mr-2 flex-shrink-0" />
          <span className="text-red-300 text-sm">{error}</span>
        </div>
      )}

      {result && (
        <div className="p-4 bg-white/10 border border-white/10 rounded-lg">
          <h3 className="text-lg font-medium text-white mb-2">Extracted Text:</h3>
          <pre className="text-white/90 text-sm whitespace-pre-wrap font-mono max-h-96 overflow-y-auto">
            {result}
          </pre>
        </div>
      )}
    </div>
  );
};

export default OCRTester;