import React, { useState } from 'react';
import { X, Save, Edit2, Trash2 } from 'lucide-react';
import type { ProcessedData } from './types';

interface GuidelineConfirmationProps {
  data: ProcessedData;
  onClose: () => void;
  onSave: (title?: string, cptCodes?: Array<{code: string; description: string}>) => void;
}

export const GuidelineConfirmation: React.FC<GuidelineConfirmationProps> = ({
  data,
  onClose,
  onSave
}) => {
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [title, setTitle] = useState(data.title);
  const [cptCodes, setCptCodes] = useState(data.cptCodes);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-lg w-full mx-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Confirm Guideline</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="space-y-4 max-h-[70vh] overflow-y-auto scrollbar">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Title</label>
            {isEditingTitle ? (
              <div className="flex gap-2">
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setIsEditingTitle(false);
                    }
                  }}
                  onBlur={() => setIsEditingTitle(false)}
                  autoFocus
                />
                <button
                  onClick={() => setIsEditingTitle(false)}
                  className="p-2 text-gray-500 hover:text-gray-700"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
            ) : (
              <div className="flex justify-between items-center p-2 bg-gray-50 rounded-lg">
                <p className="text-sm text-gray-900">{title}</p>
                <button
                  onClick={() => setIsEditingTitle(true)}
                  className="p-2 text-gray-500 hover:text-gray-700"
                >
                  <Edit2 className="h-5 w-5" />
                </button>
              </div>
            )}
          </div>

          {cptCodes.length > 0 && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                CPT Codes
              </label>
              <div className="space-y-2">
                {cptCodes.map((code, index) => (
                  <div key={index} className="flex justify-between items-center bg-gray-50 p-2 rounded-lg">
                    <div>
                      <span className="font-medium">{code.code}:</span>{' '}
                      <span className="text-gray-600">{code.description}</span>
                    </div>
                    <button
                      onClick={() => {
                        const updatedCodes = [...cptCodes];
                        updatedCodes.splice(index, 1);
                        setCptCodes(updatedCodes);
                      }}
                      className="p-1 text-red-600 hover:text-red-900"
                      title="Delete CPT code"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="mt-6 flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
          >
            Cancel
          </button>
          <button
            onClick={() => onSave(title, cptCodes)}
            className="flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
          >
            <Save className="h-4 w-4 mr-2" />
            Save Guideline
          </button>
        </div>
      </div>
    </div>
  );
};