import React, { useState } from 'react';
import { Bug, ChevronDown, ChevronUp, AlertCircle, CheckCircle2, XCircle } from 'lucide-react';
import { version } from 'pdfjs-dist/package.json';

interface DiagnosticResult {
  component: string;
  status: 'success' | 'warning' | 'error';
  message: string;
  details?: string;
}

const PDFDiagnosticsButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [results, setResults] = useState<DiagnosticResult[]>([]);

  const runDiagnostics = async () => {
    const diagnosticResults: DiagnosticResult[] = [];

    // Check PDF.js Worker
    try {
      const workerUrl = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.min.js`;
      const response = await fetch(workerUrl);
      if (!response.ok) {
        throw new Error('PDF.js worker not accessible');
      }
      diagnosticResults.push({
        component: 'PDF.js Worker',
        status: 'success',
        message: 'PDF.js worker is properly configured'
      });
    } catch (error) {
      diagnosticResults.push({
        component: 'PDF.js Worker',
        status: 'error',
        message: 'PDF.js worker configuration issue',
        details: error instanceof Error ? error.message : 'Unknown error'
      });
    }

    // Check Adobe API
    try {
      const response = await fetch('/api/process-pdf', { method: 'HEAD' });
      diagnosticResults.push({
        component: 'Adobe API',
        status: response.ok ? 'success' : 'error',
        message: response.ok ? 'Adobe API is accessible' : 'Adobe API is not accessible',
        details: response.ok ? undefined : 'API endpoint returned error status'
      });
    } catch (error) {
      diagnosticResults.push({
        component: 'Adobe API',
        status: 'error',
        message: 'Adobe API connection failed',
        details: error instanceof Error ? error.message : 'Unknown error'
      });
    }

    // Check Browser Support
    const browserIssues: string[] = [];
    if (!window.FileReader) browserIssues.push('FileReader API not supported');
    if (!window.Blob) browserIssues.push('Blob API not supported');
    if (!window.ArrayBuffer) browserIssues.push('ArrayBuffer not supported');

    diagnosticResults.push({
      component: 'Browser Compatibility',
      status: browserIssues.length ? 'error' : 'success',
      message: browserIssues.length ? 'Browser compatibility issues detected' : 'Browser supports all required features',
      details: browserIssues.join(', ')
    });

    setResults(diagnosticResults);
  };

  const getStatusIcon = (status: DiagnosticResult['status']) => {
    switch (status) {
      case 'success':
        return <CheckCircle2 className="h-5 w-5 text-green-400" />;
      case 'warning':
        return <AlertCircle className="h-5 w-5 text-yellow-400" />;
      case 'error':
        return <XCircle className="h-5 w-5 text-red-400" />;
    }
  };

  return (
    <div className="fixed bottom-4 right-4 z-50">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="w-full px-4 py-3 flex items-center justify-between bg-black text-white hover:bg-gray-900 transition-colors"
        >
          <div className="flex items-center">
            <Bug className="h-5 w-5 mr-2" />
            <span className="font-medium">PDF Diagnostics</span>
          </div>
          {isOpen ? (
            <ChevronDown className="h-5 w-5" />
          ) : (
            <ChevronUp className="h-5 w-5" />
          )}
        </button>

        {isOpen && (
          <div className="p-4">
            <button
              onClick={runDiagnostics}
              className="w-full px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
            >
              Run Diagnostics
            </button>

            {results.length > 0 && (
              <div className="mt-4 space-y-3">
                {results.map((result, index) => (
                  <div
                    key={index}
                    className={`p-3 rounded-lg ${
                      result.status === 'error'
                        ? 'bg-red-50'
                        : result.status === 'warning'
                        ? 'bg-yellow-50'
                        : 'bg-green-50'
                    }`}
                  >
                    <div className="flex items-center gap-2">
                      {getStatusIcon(result.status)}
                      <span className="font-medium">{result.component}</span>
                    </div>
                    <p className="mt-1 text-sm">{result.message}</p>
                    {result.details && (
                      <p className="mt-1 text-sm opacity-80">{result.details}</p>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PDFDiagnosticsButton;