import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Upload, ArrowRight, Loader2 } from 'lucide-react';
import { useFormStore } from '../store/formStore';
import { OCRControls } from '../components/ocr/OCRControls';
import { Alert } from '../components/ui/alert';
import PDFDiagnosticsButton from '../components/pdf/PDFDiagnosticsButton';

const OCRProcessing = () => {
  const navigate = useNavigate();
  const { setFormData } = useFormStore();
  const [file, setFile] = useState<File | null>(null);
  const [result, setResult] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const processFile = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!file) {
      setError('Please select a file first');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('apikey', 'K89206227088957');
      formData.append('language', 'eng');
      formData.append('isOverlayRequired', 'false');

      const response = await fetch('https://api.ocr.space/parse/image', {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();

      if (data.ErrorMessage) {
        throw new Error(data.ErrorMessage[0]);
      }

      setResult(data.ParsedResults[0].ParsedText);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to process the file');
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
      setError('');
    }
  };

  const handleImport = () => {
    if (result) {
      setFormData({ clinicalNotes: result });
      navigate('/dashboard');
    }
  };

  const handleReset = () => {
    setFile(null);
    setResult('');
    setError('');
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-900 via-blue-900 to-blue-800 py-8 px-4">
      <div className="max-w-4xl mx-auto">
        <div className="relative group mb-8">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500/30 to-indigo-500/30 rounded-xl blur-xl"></div>
          <div className="relative backdrop-blur-md bg-white/10 border border-white/20 p-6 rounded-xl">
            <h1 className="text-2xl font-bold text-white mb-6">Adobe PDF OCR</h1>
            
            <div className="space-y-6">
              <div className="flex items-center justify-center w-full">
                <label className="flex flex-col items-center justify-center w-full h-64 border-2 border-dashed rounded-lg cursor-pointer bg-white/5 border-white/20 hover:bg-white/10">
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <Upload className="w-8 h-8 text-white/80" />
                    <p className="mb-2 text-sm text-white/80">
                      <span className="font-semibold">Click to upload</span> or drag and drop
                    </p>
                    <p className="text-xs text-white/60">
                      Supports PDF, video, and common image formats
                    </p>
                  </div>
                  <input
                    type="file"
                    className="hidden"
                    accept="application/pdf,image/*,video/*"
                    onChange={handleFileChange}
                  />
                </label>
              </div>

              {file && (
                <div className="flex items-center space-x-2">
                  <span className="text-sm text-white/80">Selected file: {file.name}</span>
                </div>
              )}

              <div className="flex justify-center space-x-4">
                <button
                  onClick={processFile}
                  disabled={!file || loading}
                  className="px-6 py-3 text-white bg-gradient-to-r from-blue-500 to-indigo-500 rounded-xl hover:from-blue-600 hover:to-indigo-600 disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2 transition-all duration-300 shadow-lg hover:shadow-xl"
                >
                  {loading ? (
                    <>
                      <Loader2 className="w-4 h-4 animate-spin" />
                      <span>Processing...</span>
                    </>
                  ) : (
                    <span>Process Document</span>
                  )}
                </button>
              </div>

              {error && (
                <Alert variant="destructive" className="bg-red-500/20 text-red-300 border-red-500/20">
                  {error}
                </Alert>
              )}

              {result && (
                <div className="space-y-4">
                  <div className="flex justify-end space-x-2">
                    <OCRControls 
                      text={result}
                      onReset={handleReset}
                    />
                    <button
                      onClick={handleImport}
                      className="flex items-center px-3 py-1.5 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
                    >
                      Import to Clinical Notes
                      <ArrowRight className="h-4 w-4 ml-2" />
                    </button>
                  </div>

                  <div className="p-4 bg-white/10 border border-white/10 rounded-lg">
                    <pre className="text-white/90 text-sm whitespace-pre-wrap font-mono max-h-96 overflow-y-auto">
                      {result}
                    </pre>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <PDFDiagnosticsButton />
    </div>
  );
};

export default OCRProcessing;