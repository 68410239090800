import { useState, useCallback } from 'react';
import { useData } from '../../../../context/DataContext';
import { OpenAIService } from '../../../../services/openai/service';
import { extractCptCodes } from '../../../../utils/cptCodeUtils';
import Fuse from 'fuse.js';
import type { ProcessedData, Duplicate } from '../types';

export const useGuidelineProcessor = () => {
  const { cptCodes, criteria, procedures, updateData } = useData();
  const [guidelineText, setGuidelineText] = useState('');
  const [selectedLOB, setSelectedLOB] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showDuplicateConfirmation, setShowDuplicateConfirmation] = useState(false);
  const [duplicates, setDuplicates] = useState<Duplicate[]>([]);
  const [processedData, setProcessedData] = useState<ProcessedData | null>(null);

  const findSimilarGuidelines = (title: string) => {
    const fuse = new Fuse(criteria, {
      keys: ['guideline'],
      threshold: 0.3,
      includeScore: true,
      ignoreLocation: true
    });

    return fuse.search(title)
      .filter(result => result.score && result.score < 0.3)
      .map(result => ({
        guideline: result.item.guideline,
        similarity: 1 - (result.score || 0),
        originalData: result.item
      }));
  };

  const handleProcess = async () => {
    if (!guidelineText.trim()) {
      setError('Please enter guideline text');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const lines = guidelineText.trim().split('\n');
      const extractedTitle = lines[0].trim();
      const extractedCodes = extractCptCodes(guidelineText);
      
      // Find similar guidelines
      const similarGuidelines = findSimilarGuidelines(extractedTitle);
      if (similarGuidelines.length > 0) {
        setDuplicates(similarGuidelines);
        setShowDuplicateConfirmation(true);
        return;
      }
      
      // Process CPT codes
      const codeDescriptions = await Promise.all(
        extractedCodes.map(async code => {
          const existingProcedure = procedures.find(p => p.code === code);
          if (existingProcedure) {
            return { code, description: existingProcedure.description };
          }
          const response = await OpenAIService.analyzeClinicalNotes(code, 'get_description', []);
          return { code, description: response.description || '' };
        })
      );

      setProcessedData({
        title: extractedTitle,
        cptCodes: codeDescriptions,
        lob: selectedLOB
      });
      setShowConfirmation(true);
    } catch (err) {
      console.error('Error extracting guideline info:', err);
      setError(err instanceof Error ? err.message : 'Failed to process guideline');
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (selectedGuidelineIds: string[] = [], updatedTitle?: string) => {
    try {
      setLoading(true);
      setError(null);

      const title = updatedTitle || processedData?.title || '';
      const codes = processedData?.cptCodes || [];

      // If updating existing guidelines
      if (selectedGuidelineIds.length > 0) {
        const updatedCriteria = criteria.map(c => {
          if (selectedGuidelineIds.includes(c.id)) {
            return {
              ...c,
              guideline: title,
              criteria: guidelineText,
              timestamp: new Date()
            };
          }
          return c;
        });
        await updateData('criteria', updatedCriteria);
      } else {
        // Add new guideline
        await updateData('criteria', [...criteria, {
          id: crypto.randomUUID(),
          guideline: title,
          criteria: guidelineText,
          timestamp: new Date()
        }]);
      }

      // Add new procedures if needed
      const newProcedures = codes
        .filter(code => !procedures.some(p => p.code === code.code))
        .map(code => ({
          code: code.code,
          description: code.description,
          procedureMd: '0'
        }));

      if (newProcedures.length > 0) {
        await updateData('procedures', [...procedures, ...newProcedures]);
      }

      // Add CPT code links if LOB is selected
      if (selectedLOB) {
        const newCptCodes = codes.map(code => ({
          code: code.code,
          lob: selectedLOB,
          guideline: title
        }));
        await updateData('cptCodes', [...cptCodes, ...newCptCodes]);
      }

      // Reset state
      setGuidelineText('');
      setSelectedLOB('');
      setShowConfirmation(false);
      setShowDuplicateConfirmation(false);
      setDuplicates([]);
      setProcessedData(null);

    } catch (err) {
      console.error('Error saving guideline:', err);
      setError('Failed to save guideline. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return {
    guidelineText,
    setGuidelineText,
    selectedLOB,
    setSelectedLOB,
    loading,
    error,
    processedData,
    duplicates,
    showConfirmation,
    showDuplicateConfirmation,
    handleProcess,
    handleSave,
    setShowConfirmation,
    setShowDuplicateConfirmation
  };
};