import { useState, useCallback } from 'react';
import { Review } from '../types';
import { OpenAIService } from '../services/openai/service';
import { truncateText } from '../services/openai/tokenizer';

interface ReviewGenerationState {
  isGenerating: boolean;
  error: string | null;
}

interface CPTDescription {
  code: string;
  description: string;
}

export const useReviewGeneration = () => {
  const [state, setState] = useState<ReviewGenerationState>({
    isGenerating: false,
    error: null
  });

  const generateReview = useCallback(async (
    clinicalNotes: string,
    cptDescriptions: CPTDescription[],
    selectedGuidelines: string[],
    aiFocus?: string
  ): Promise<Review | null> => {
    if (!clinicalNotes?.trim()) {
      throw new Error('Clinical notes are required');
    }

    if (!selectedGuidelines?.length) {
      throw new Error('Please select at least one guideline');
    }

    setState({ isGenerating: true, error: null });

    try {
      // Format CPT descriptions
      const formattedCptDescriptions = cptDescriptions
        .map(desc => `${desc.code}: ${desc.description}`)
        .join('\n');

      // Add AI focus if provided
      const notesWithFocus = aiFocus 
        ? `Focus Area: ${aiFocus}\n\nClinical Notes:\n${clinicalNotes}`
        : clinicalNotes;

      // Process in parallel with error handling
      const [dateResponse, medicalHistory, recommendation] = await Promise.all([
        OpenAIService.analyzeClinicalNotes(notesWithFocus, 'date_of_service', []),
        OpenAIService.analyzeClinicalNotes(notesWithFocus, 'medical_history', []),
        OpenAIService.analyzeClinicalNotes(
          `${notesWithFocus}\n\nCPT Codes:\n${formattedCptDescriptions}\n\nGuidelines to Review:\n${selectedGuidelines.join('\n')}`, 
          'recommendation',
          selectedGuidelines
        )
      ]);

      const review: Review = {
        reasonForRequest: formattedCptDescriptions || 'No CPT code description available',
        dateOfService: dateResponse.summary || new Date().toLocaleDateString(),
        medicalHistory: medicalHistory.summary || 'No medical history found',
        criteriaApplied: selectedGuidelines,
        recommendation: recommendation.summary || 'Please select guidelines for review',
        signature: 'C. Starks, LVN',
        additionalNote: 'N/A, current attached clinical notes reviewed.'
      };

      setState({ isGenerating: false, error: null });
      return review;

    } catch (error) {
      console.error('Error generating review:', error);
      setState({
        isGenerating: false,
        error: error instanceof Error ? error.message : 'Failed to generate review'
      });
      throw error;
    }
  }, []);

  return {
    ...state,
    generateReview
  };
};