import React from 'react';
import { useGuidelineProcessor } from './guideline/hooks/useGuidelineProcessor';
import { GuidelineInput } from './guideline/GuidelineInput';
import { GuidelineConfirmation } from './guideline/GuidelineConfirmation';
import { DuplicateConfirmation } from './guideline/DuplicateConfirmation';
import { ProcessingStatus } from './guideline/ProcessingStatus';

const GuidelineProcessor: React.FC = () => {
  const {
    guidelineText,
    setGuidelineText,
    selectedLOB,
    setSelectedLOB,
    loading,
    error,
    processedData,
    duplicates,
    showConfirmation,
    showDuplicateConfirmation,
    handleProcess,
    handleSave,
    setShowConfirmation,
    setShowDuplicateConfirmation
  } = useGuidelineProcessor();

  return (
    <div className="space-y-4 max-h-[calc(100vh-200px)] overflow-y-auto scrollbar">
      <GuidelineInput
        value={guidelineText}
        onChange={setGuidelineText}
        selectedLOB={selectedLOB}
        onLOBChange={setSelectedLOB}
        onProcess={handleProcess}
        disabled={loading}
      />

      <ProcessingStatus loading={loading} error={error} />

      {showConfirmation && processedData && (
        <GuidelineConfirmation
          data={processedData}
          onClose={() => setShowConfirmation(false)}
          onSave={(title) => handleSave([], title)}
        />
      )}

      {showDuplicateConfirmation && duplicates.length > 0 && (
        <DuplicateConfirmation
          duplicates={duplicates}
          onClose={() => setShowDuplicateConfirmation(false)}
          onConfirm={(selectedIds, title) => handleSave(selectedIds, title)}
        />
      )}
    </div>
  );
};

export default GuidelineProcessor;