import { OpenAIError } from '../errors';
import { logError } from '../../../utils/logging';

export const handleOpenAIError = (error: unknown): never => {
  const errorDetails = {
    timestamp: new Date().toISOString(),
    error: error instanceof Error ? {
      name: error.name,
      message: error.message,
      stack: error.stack
    } : 'Unknown error'
  };

  logError('OpenAI API error', errorDetails);

  if (error instanceof OpenAIError) {
    throw error;
  }

  throw OpenAIError.fromError(error);
};