import OpenAI from 'openai';
import { openAISettings } from '../config/settings';
import { OpenAIError } from '../errors';

class OpenAIClient {
  private static instance: OpenAIClient;
  private client: OpenAI;

  private constructor() {
    if (!openAISettings.apiKey) {
      throw new OpenAIError('OpenAI API key is not configured', 'missing_api_key');
    }

    this.client = new OpenAI({
      apiKey: openAISettings.apiKey,
      baseURL: openAISettings.baseURL,
      timeout: openAISettings.timeout,
      maxRetries: openAISettings.retries,
      dangerouslyAllowBrowser: true
    });
  }

  static getInstance(): OpenAIClient {
    if (!OpenAIClient.instance) {
      OpenAIClient.instance = new OpenAIClient();
    }
    return OpenAIClient.instance;
  }

  getClient(): OpenAI {
    return this.client;
  }
}

export const openAIClient = OpenAIClient.getInstance();
export const openai = openAIClient.getClient();