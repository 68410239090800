export class OCRSpaceAPI {
  // ... existing code ...

  static async processImage(file: File, customFormData?: FormData): Promise<string> {
    let lastError: Error | null = null;

    for (let attempt = 0; attempt < this.MAX_RETRIES; attempt++) {
      try {
        const formData = customFormData || OCRRequestBuilder.buildFormData(file);
        
        const response = await fetch(ocrConfig.apiEndpoint, {
          method: 'POST',
          headers: {
            'apikey': ocrConfig.apiKey
          },
          body: formData
        });

        if (!response.ok) {
          throw new OCRError(`API request failed with status ${response.status}`);
        }

        const result = await response.json();
        return OCRResponseParser.parseResponse(result);

      } catch (error) {
        lastError = error as Error;
        if (attempt < this.MAX_RETRIES - 1) {
          await new Promise(resolve => setTimeout(resolve, this.RETRY_DELAY));
        }
      }
    }

    throw lastError || new OCRError('All OCR attempts failed');
  }
}