import React from 'react';
import { AlertCircle } from 'lucide-react';

interface ProcessingStatusProps {
  loading: boolean;
  error: string | null;
}

export const ProcessingStatus: React.FC<ProcessingStatusProps> = ({
  loading,
  error
}) => {
  if (!loading && !error) return null;

  return (
    <div className="mt-4">
      {error && (
        <div className="p-4 bg-red-500/20 rounded-lg flex items-center">
          <AlertCircle className="h-5 w-5 text-red-400 mr-2" />
          <span className="text-sm text-red-300">{error}</span>
        </div>
      )}
    </div>
  );
};