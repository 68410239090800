import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Shield, FileText } from 'lucide-react';
import { useData } from '../context/DataContext';
import LoadingSpinner from '../components/LoadingSpinner';
import CptCodesTab from '../components/admin/CptCodesTab';
import CriteriaTab from '../components/admin/CriteriaTab';
import ProceduresTab from '../components/admin/ProceduresTab';
import LobTab from '../components/admin/LobTab';
import GuidelineProcessor from '../components/admin/GuidelineProcessor';
import DuplicatesTab from '../components/admin/DuplicatesTab';
import AISettingsTab from '../components/admin/AISettingsTab';

const AdminPanel = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { loadData } = useData();
  const [currentTab, setCurrentTab] = useState(() => {
    const hash = location.hash.slice(1);
    return hash || 'cptCodes';
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadTabData = async () => {
      setLoading(true);
      try {
        switch (currentTab) {
          case 'cptCodes':
            await loadData('cptCodes');
            break;
          case 'criteria':
            await loadData('criteria');
            break;
          case 'procedures':
            await loadData('procedures');
            break;
          case 'duplicates':
            await Promise.all([
              loadData('cptCodes'),
              loadData('criteria')
            ]);
            break;
        }
      } catch (error) {
        console.error('Error loading data:', error);
      } finally {
        setLoading(false);
      }
    };
    loadTabData();
  }, [currentTab, loadData]);

  const handleTabChange = (tab: string) => {
    setCurrentTab(tab);
    navigate(`#${tab}`, { replace: true });
  };

  const renderTabContent = () => {
    if (loading) {
      return <LoadingSpinner />;
    }

    switch (currentTab) {
      case 'cptCodes':
        return <CptCodesTab />;
      case 'criteria':
        return <CriteriaTab />;
      case 'procedures':
        return <ProceduresTab />;
      case 'lob':
        return <LobTab />;
      case 'processor':
        return <GuidelineProcessor />;
      case 'duplicates':
        return <DuplicatesTab />;
      case 'aiSettings':
        return <AISettingsTab />;
      default:
        return <CptCodesTab />;
    }
  };

  const tabs = [
    { id: 'cptCodes', label: 'CPT Codes' },
    { id: 'criteria', label: 'Criteria' },
    { id: 'procedures', label: 'Procedures' },
    { id: 'lob', label: 'Lines of Business' },
    { id: 'processor', label: 'AI Processor' },
    { id: 'duplicates', label: 'Duplicates' },
    { id: 'aiSettings', label: 'AI Settings' }
  ];

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center">
            <Shield className="h-6 w-6 text-indigo-600 mr-2" />
            <h1 className="text-2xl font-bold text-gray-900">Admin Panel</h1>
          </div>
          <Link 
            to="/dashboard"
            className="flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
          >
            <FileText className="h-4 w-4 mr-2" />
            UM Note Taker
          </Link>
        </div>

        <div className="bg-white rounded-lg shadow-sm">
          <div className="border-b border-gray-200">
            <nav className="flex -mb-px">
              {tabs.map(tab => (
                <button
                  key={tab.id}
                  onClick={() => handleTabChange(tab.id)}
                  className={`
                    py-4 px-6 text-sm font-medium border-b-2 whitespace-nowrap
                    ${currentTab === tab.id
                      ? 'border-indigo-600 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    }
                  `}
                >
                  {tab.label}
                </button>
              ))}
            </nav>
          </div>

          <div className="p-6">
            {renderTabContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;