import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './context/AuthContext';
import { DataProvider } from './context/DataContext';
import Navbar from './components/Navbar';
import AppRoutes from './routes/AppRoutes';
import LoadingFallback from './components/LoadingFallback';
import ErrorFallback from './components/ErrorFallback';

function App() {
  const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;

  if (!clientId) {
    console.warn('Google Client ID is not configured. Social login will be disabled.');
    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <BrowserRouter>
          <AuthProvider>
            <DataProvider>
              <div className="app-container">
                <Navbar />
                <AppRoutes />
              </div>
            </DataProvider>
          </AuthProvider>
        </BrowserRouter>
      </ErrorBoundary>
    );
  }

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <BrowserRouter>
          <AuthProvider>
            <DataProvider>
              <div className="app-container">
                <Navbar />
                <AppRoutes />
              </div>
            </DataProvider>
          </AuthProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </GoogleOAuthProvider>
  );
}

export default App;