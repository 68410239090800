import React, { useState } from 'react';
import { Copy, RotateCcw, Check } from 'lucide-react';

interface OCRControlsProps {
  text: string;
  onReset: () => void;
  className?: string;
}

export const OCRControls: React.FC<OCRControlsProps> = ({
  text,
  onReset,
  className = ''
}) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  return (
    <div className={`flex items-center gap-2 ${className}`}>
      <button
        onClick={onReset}
        className="flex items-center px-3 py-1.5 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
        title="Reset"
      >
        <RotateCcw className="h-4 w-4 mr-2" />
        Reset
      </button>
      
      <button
        onClick={handleCopy}
        disabled={!text}
        className="flex items-center px-3 py-1.5 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition-colors disabled:opacity-50"
        title="Copy text"
      >
        {copied ? (
          <>
            <Check className="h-4 w-4 mr-2" />
            Copied!
          </>
        ) : (
          <>
            <Copy className="h-4 w-4 mr-2" />
            Copy
          </>
        )}
      </button>
    </div>
  );
};