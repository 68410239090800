import React, { useState } from 'react';
import { Clock, Copy, Check, RefreshCw } from 'lucide-react';
import { Review } from '../types';
import { useData } from '../context/DataContext';
import { useAudioStore } from '../store/audioStore';
import AutoReadSummary from './AutoReadSummary';
import AudioControls from './audio/AudioControls';

interface ReviewOutputProps {
  review: Review;
  cptCodes?: string[];
  onRegenerate?: () => void;
}

const ReviewOutput: React.FC<ReviewOutputProps> = ({ review, cptCodes = [], onRegenerate }) => {
  const [copiedSection, setCopiedSection] = useState<string | null>(null);
  const { procedures } = useData();
  const { isMuted } = useAudioStore();

  const copyToClipboard = (text: string, section: string) => {
    navigator.clipboard.writeText(text);
    setCopiedSection(section);
    setTimeout(() => setCopiedSection(null), 2000);
  };

  // Get descriptions without CPT codes
  const descriptions = cptCodes
    ?.map(code => {
      const procedure = procedures.find(p => p.code === code);
      return procedure?.description;
    })
    .filter(Boolean);

  const sections = [
    { 
      title: 'Reason for Request', 
      content: descriptions?.length 
        ? descriptions.join('. ') + '.'
        : 'No CPT code descriptions available.'
    },
    { 
      title: 'Date of Service', 
      content: review.dateOfService ? new Date(review.dateOfService).toLocaleDateString() : ''
    },
    { title: 'Medical History', content: review.medicalHistory },
    { 
      title: 'Recommendation', 
      content: review.recommendation,
      showRegenerate: true
    },
    { title: 'Signature', content: review.signature },
    { title: 'Additional Note', content: review.additionalNote }
  ];

  return (
    <div className="bg-white rounded-lg shadow p-6">
      {/* Rest of the component remains the same */}
    </div>
  );
};

export default ReviewOutput;