import React, { useState } from 'react';
import { AlertCircle, Edit2 } from 'lucide-react';
import type { Duplicate } from './types';

interface DuplicateConfirmationProps {
  duplicates: Duplicate[];
  onClose: () => void;
  onConfirm: (selectedGuidelineIds: string[], updatedTitle?: string) => void;
}

export const DuplicateConfirmation: React.FC<DuplicateConfirmationProps> = ({
  duplicates,
  onClose,
  onConfirm
}) => {
  const [selectedGuidelineIds, setSelectedGuidelineIds] = useState<string[]>([]);
  const [editedTitle, setEditedTitle] = useState(duplicates[0]?.guideline || '');
  const [isEditing, setIsEditing] = useState(false);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-lg w-full mx-4">
        <div className="flex items-center mb-4">
          <AlertCircle className="h-5 w-5 text-amber-500 mr-2" />
          <h2 className="text-xl font-semibold">Similar Guidelines Found</h2>
        </div>

        <div className="text-gray-600 mb-4">
          <p>The following similar guidelines already exist. You can:</p>
          <div className="mt-2">
            <div className="ml-4">• Edit the new guideline title</div>
            <div className="ml-4">• Select guidelines to update with new content</div>
            <div className="ml-4">• Create as a new guideline</div>
          </div>
        </div>

        <div className="space-y-4 mb-6 max-h-[300px] overflow-y-auto">
          {duplicates.map((duplicate, index) => (
            <div key={index} className="p-4 bg-gray-50 rounded-lg">
              <div className="flex justify-between items-start">
                <div className="flex-1">
                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      checked={selectedGuidelineIds.includes(duplicate.originalData.id)}
                      onChange={(e) => {
                        setSelectedGuidelineIds(prev => 
                          e.target.checked 
                            ? [...prev, duplicate.originalData.id]
                            : prev.filter(id => id !== duplicate.originalData.id)
                        );
                      }}
                      className="h-4 w-4 text-indigo-600 rounded border-gray-300"
                    />
                    <p className="font-medium text-gray-900">{duplicate.guideline}</p>
                  </div>
                  <p className="text-sm text-gray-500 mt-1">
                    Similarity: {Math.round(duplicate.similarity * 100)}%
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {isEditing ? (
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              New Guideline Title
            </label>
            <input
              type="text"
              value={editedTitle}
              onChange={(e) => setEditedTitle(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
              autoFocus
            />
          </div>
        ) : (
          <button
            onClick={() => setIsEditing(true)}
            className="flex items-center text-indigo-600 hover:text-indigo-800 mb-4"
          >
            <Edit2 className="h-4 w-4 mr-1" />
            Edit Title
          </button>
        )}

        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
          >
            Cancel
          </button>
          <button
            onClick={() => onConfirm(selectedGuidelineIds, isEditing ? editedTitle : undefined)}
            className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
          >
            {selectedGuidelineIds.length > 0 ? 'Update Selected' : 'Create New'}
          </button>
        </div>
      </div>
    </div>
  );
};