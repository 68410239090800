import { generateCompletion } from './api/completion';
import { OpenAIError } from './errors';
import { AIResponse } from './types';
import { useAISettings } from '../../store/aiSettingsStore';

export class OpenAIService {
  private static readonly MAX_RETRIES = 3;
  private static readonly RETRY_DELAY = 1000;

  private static getPromptForType(type: string) {
    const { settings } = useAISettings.getState();
    
    if (!settings) {
      throw new OpenAIError(
        'AI settings not found. Please configure settings in the Admin Panel.',
        'missing_settings'
      );
    }

    switch (type) {
      case 'clinical_summary':
        if (!settings.clinicalSummaryPrompt?.trim()) {
          throw new OpenAIError('Clinical Summary prompt not configured', 'missing_prompt');
        }
        return settings.clinicalSummaryPrompt;

      case 'medical_history':
        if (!settings.medicalHistoryPrompt?.trim()) {
          throw new OpenAIError('Medical History prompt not configured', 'missing_prompt');
        }
        return settings.medicalHistoryPrompt;

      case 'recommendation':
        if (!settings.recommendationPrompt?.trim()) {
          throw new OpenAIError('Recommendation prompt not configured', 'missing_prompt');
        }
        return settings.recommendationPrompt;

      case 'get_description':
        return 'Provide a clear, concise description of the CPT code. Return only the description without any additional commentary.';

      default:
        throw new OpenAIError(`Invalid prompt type: ${type}`, 'invalid_type');
    }
  }

  static async analyzeClinicalNotes(
    text: string,
    type: 'medical_history' | 'recommendation' | 'clinical_summary' | 'get_description',
    guidelines: string[] = []
  ): Promise<AIResponse> {
    if (!text?.trim()) {
      throw new OpenAIError('Text input is required', 'invalid_input');
    }

    let lastError: Error | null = null;
    let attempts = 0;

    while (attempts < this.MAX_RETRIES) {
      try {
        const prompt = this.getPromptForType(type);
        const content = await generateCompletion(text, prompt, guidelines);

        return {
          summary: content.trim(),
          review: content.trim(),
          description: content.trim()
        };
      } catch (error: any) {
        lastError = error;
        attempts++;

        // Don't retry on certain errors
        if (error.status === 401 || error.message?.includes('invalid_api_key')) {
          throw new OpenAIError('Invalid API key configuration', 'auth_error');
        }

        if (attempts < this.MAX_RETRIES) {
          console.warn(`Attempt ${attempts} failed, retrying...`, error);
          await new Promise(resolve => 
            setTimeout(resolve, this.RETRY_DELAY * attempts)
          );
        }
      }
    }

    console.error('All retry attempts failed:', lastError);
    throw new OpenAIError(
      'Failed to process request after multiple attempts. Please try again later.',
      'max_retries_exceeded'
    );
  }
}