import React from 'react';
import { AlertCircle } from 'lucide-react';
import type { SummaryDisplayProps } from './types';

export const SummaryDisplay: React.FC<SummaryDisplayProps> = ({
  summary,
  keyFindings,
  recommendations,
  isLoading,
  error
}) => {
  if (error) {
    return (
      <div className="mt-2 p-3 bg-red-500/20 rounded-md flex items-center">
        <AlertCircle className="h-5 w-5 text-red-400 mr-2" />
        <span className="text-sm text-red-300">{error}</span>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="mt-2 p-4 bg-white/5 rounded-md animate-pulse">
        <div className="h-4 bg-white/10 rounded w-3/4 mb-2"></div>
        <div className="h-4 bg-white/10 rounded w-1/2"></div>
      </div>
    );
  }

  if (!summary) return null;

  return (
    <div className="mt-2 p-4 bg-white/10 rounded-md">
      <p className="text-sm text-white/90">{summary}</p>
      
      {keyFindings?.length > 0 && (
        <div className="mt-4">
          <h4 className="text-sm font-medium text-white/90 mb-2">Key Findings</h4>
          <ul className="list-disc list-inside space-y-1">
            {keyFindings.map((finding, index) => (
              <li key={index} className="text-sm text-white/80">{finding}</li>
            ))}
          </ul>
        </div>
      )}

      {recommendations?.length > 0 && (
        <div className="mt-4">
          <h4 className="text-sm font-medium text-white/90 mb-2">Recommendations</h4>
          <ul className="list-disc list-inside space-y-1">
            {recommendations.map((rec, index) => (
              <li key={index} className="text-sm text-white/80">{rec}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};